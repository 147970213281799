import React, { useState } from "react"
import "./styles/faq.scss"

const listItems = [
  {
    id: 0,
    title: "How do I join Afyabook?",
    body: "Just sign up under Registration or Login",
  },
  {
    id: 1,
    title: "How do I join Afyabook Health Programs?",
    body: "Once you have identified the health program you would like to participate in, sign up and our team will reach out to you with onboarding details",
  },
  {
    id: 3,
    title: "What kind of resources will I get from Afyabook Health Programs",
    body: "Medication Guides,Medication Tracking Tools, Personalized Action Plans for chronic conditions, Educational Webinars, Support Groups,Vaccination Schedules, Reminder Tools, Smoking Quit Plans, Healthy Eating Guides,Fitness Resources, Health Screening Information, Risk Factor Checklists, Articles, videos, and infographics on health topics relevant to your patients, Workshops and Seminars, Personalized Education Sessions, Nutritional Meal Planning Templates, Nutritional Workshops, Mental Health Resources hotlines, Counseling services, and community mental health resources, Self-Care Tips",
  },
  // {
  //   id: 4,
  //   title: "What other benefits do I get through Afyabook",
  //   body: "Access to credit facilities. Business consulting and support. Pooled purchase benefits, reduces cost. A data intensive platform for insight discovery",
  // },
]
export default function Faq() {
  const [active, setActive] = useState(null)
  return (
    <div className="faq">
      <section className="list">
        <div className="faqheader">
          <h1 className="heading">Frequent Questions</h1>
          <h3 className="subheading">Some frequent questions clients ask</h3>
        </div>
        {listItems.map(item => (
          <button
            key={item.id}
            className="list-item"
            open={active === item.id || false}
            onClick={e => {
              e.preventDefault()
              if (active === item.id) {
                setActive(null)
              } else {
                setActive(item.id)
              }
            }}
          >
            <header className="list-item-title">
              <p>{item.title}</p>
              <i className="list-item-plus-icon" />
            </header>
            <div className="list-item-body">
              <article className="list-item-article">{item.body}</article>
            </div>
          </button>
        ))}
      </section>
    </div>
  )
}
