// Modal.js
import React, { useState } from "react"
import axios from "axios"
import {  Subtitle} from "../infoSection/infoSectionElements"
import {
  HeaderWrapper,
  ModalHeader,
  StyledButton,
  StyledForm,
  StyledIcon,
  StyledInput,
  SuccessContainer,
  SuccessHeader,
  SuccessImg,
  SuccessText,
} from "../providerSection/providerElements"
import { AnimatePresence } from "framer-motion"
import HeroSuccess from "../banners/thankyou.svg"
import useAuthState from "../../stores/auth"
import styled from 'styled-components';

const ModalReg = ({ isOpen, onClose, program }) => {
  if (!isOpen) return null;

  const { user } = useAuthState()

  const [name, setName] = useState(
    user?.firstName
      ? user.firstName
      : "" + "" + user?.lastName
      ? user.lastName
      : ""
  )
  const [email, setEmail] = useState(user?.email || "")

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const uploadData = async () => {
    try {
      setError("")
      setSuccess("")
      if (!name || !email) {
        setError("Missing required fields")
      } else {
        setLoading(true)

        const data = {
          name,
          email,
          type: program.title,
          //change type for each page
        }
        await axios.post(`${process.env.GATSBY_API_URL}/registrations/`, {
          ...data,
        })
        //uncomment later on

        setSuccess("Success")
        setLoading(false)
        setSubmitted(true)
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: EditProgram.js ~ line 54 ~ editProgram ~ error",
        err
      )
      setLoading(false)
      const { message } = err.response.data
      setError(message)
    }
  }

  return (
    <ModalOverlay>
      <ModalContent>
         <StyledForm>
              <AnimatePresence
                initial={false}
                exitBeforeEnter={true}
                onExitComplete={() => null}
              >
                {success && (
                  <>
                    <SuccessContainer>
                      <StyledIcon onClick={onClose} />
                      <SuccessImg src={HeroSuccess} />
                      <SuccessHeader>Welcome onboard {name}</SuccessHeader>
                      {/* <SuccessText>Thank you for joining</SuccessText> */}
                      <SuccessText>Our team will get in touch shortly</SuccessText>
                      
                    </SuccessContainer>
                  </>
                )}
              </AnimatePresence>
              {!success && (
                <>
                  <StyledIcon onClick={onClose} style={{ marginBottom: "10px" }}/>
                  <HeaderWrapper >
                    {/* <ModalImg src={BannerBg} /> */}
                    <ModalHeader style={{ lineHeight: "25px" }}>
                    {program.title}
                    </ModalHeader>
                    <Subtitle style={{ marginBottom: "0px", fontSize:"14px" }}>
                        { program.modalDesc} 
                    </Subtitle>
                  </HeaderWrapper>
                  <p style={{ color: "red" }}>{error}</p>
                  <label style={{ textAlign: "left" }} htmlFor="name">Name</label>
                  <StyledInput
                    type="text"
                    name="name"
                    value={name}
                    onChange={e => {
                      setName(e.target.value)
                    }}
                  />
                  <label style={{ textAlign: "left" }} htmlFor="email">Email</label>
                  <StyledInput
                    type="email"
                    name="email"
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value)
                    }}
                  />
                  {loading && (
                    <p style={{ color: "green", fontSize: "1.2rem" }}>
                      Loading...
                    </p>
                  )}
                  <StyledButton
                    disabled={submitted || loading || !name || !email}
                    onClick={() =>
                      uploadData() && onClose
                    }
                    style={{ height: "100%", padding: "10px 7.5px" }}
                  >
                    {program.ctaText}
                  </StyledButton>{" "}
                </>
              )}
            </StyledForm>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow-y: auto;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  text-align: center;
`;

export default ModalReg;
